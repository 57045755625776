<script>
import ge2gjnlService from '@/services/entry/ge2gjnl.js'

export default {
  props: {
    rowSelected: Object
  },
  data() {
    return {
      isDeleteAllow: null,
      id: null,
      journalCode: null,
      journalName: null,
      reason: '',
      isLoading: false
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.rowSelected) {
        this.modalSetup()
        this.fetchDeletePermission(this.rowSelected.id)
      }
    },
    modalSetup() {
      this.id = this.rowSelected.id
      this.journalCode = this.rowSelected.journalCode
      this.journalName = this.rowSelected.journalName
    },
    async fetchDeletePermission(id) {
      try {
        await ge2gjnlService.getGe2gjnlDeletePermissionById(id)
        this.isDeleteAllow = true
      } catch (err) {
        this.isDeleteAllow = false
        this.reason = err.response.data.thMessage
      }
    },
    async confirmButtonClicked() {
      this.isLoading = true
      try {
        await ge2gjnlService.deleteGe2gjnlById(this.id)
        this.$emit('closeModal', {
          from: 'deleteSuccess',
          content: 'ลบสมุดรายวันสำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        this.$emit('closeModal', {
          from: 'deleteError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 1000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    cancelButtonClicked() {
      this.$emit('closeModal', { from: 'deleteCancel' })
    },
    useStyles(key) {
      switch (key) {
        case 'icon-style':
          return { width: '100%', height: '100%' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <div>
    <!-- have selected row -->
    <div v-if="rowSelected">
      <!-- content -->
      <b-row align-v="center" class="mb-5">
        <!-- trash icon -->
        <b-col cols="3">
          <b-icon
            icon="trash-fill"
            variant="danger"
            class="mt-2"
            :style="useStyles('icon-style')"
          ></b-icon>
        </b-col>

        <!-- delete allow -->
        <b-col cols="9" v-if="isDeleteAllow">
          <h5>{{ journalName }} ({{ journalCode }})</h5>
          <div class="mt-3">
            <span>
              ท่านยืนยันที่จะลบสมุดรายวันนี้ใช่หรือไม่
            </span>
          </div>
        </b-col>

        <!-- delete not allow -->
        <b-col v-else>
          <h5>{{ journalName }} ({{ journalCode }})</h5>
          <div class="mt-3">
            <span class="font-weight-bold text-danger h5">
              {{ reason }}
            </span>
          </div>
        </b-col>
      </b-row>

      <!-- buttons -->
      <hr />
      <b-row v-if="isDeleteAllow" align-h="end">
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="outline-danger"
            @click="cancelButtonClicked()"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="danger"
            @click="confirmButtonClicked()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>ยืนยัน</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else align-h="end">
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="outline-danger"
            @click="cancelButtonClicked()"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- no selected row -->
    <div v-else>
      <span>กรุณาเลือกสมุดรายวันที่ต้องการจะลบ</span>
    </div>
  </div>
</template>

<style></style>
