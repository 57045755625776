<script>
import validator from 'validator'

import ge2gjnlService from '@/services/entry/ge2gjnl.js'

export default {
  props: {
    rowSelected: Object
  },
  data() {
    return {
      journalCode: null,
      journalName: null,
      errors: null,
      errorsResponse: null,
      isLoading: false
    }
  },
  created() {
    this.initialized()
  },
  methods: {
    initialized() {
      if (this.rowSelected) {
        this.modalSetup()
      }
    },
    modalSetup() {
      this.journalCode = this.rowSelected.journalCode
      this.journalName = this.rowSelected.journalName
    },
    async updateButtonClicked() {
      this.errorsResponse = null
      if (!this.checkValidationRule()) return
      try {
        this.isLoading = true
        const body = { journalName: this.journalName }
        await ge2gjnlService.updateGe2gjnlById(this.rowSelected.id, body)
        this.$emit('closeModal', {
          from: 'updateSuccess',
          content: 'แก้ไขสมุดรายวันสำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        this.errorsResponse = err.response.data
        this.$emit('closeModal', {
          from: 'updateError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 1000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    checkValidationRule() {
      this.errors = null
      const errors = {}

      // journalName
      if (!this.journalName || this.journalName.trim().length <= 0) {
        errors.journalName = 'จำเป็นต้องกรอก ชื่อสมุดรายวัน'
      } else if (!validator.isLength(this.journalName, { min: 1, max: 30 })) {
        errors.journalName = 'ชื่อสมุดรายวัน มีได้มากสุดแค่ 30 ตัวอักษรเท่านั้น'
      }

      // if errors !== {} then not pass
      if (Object.keys(errors).length > 0) {
        this.errors = errors
        return false
      }
      return true
    },
    cancelButtonClicked() {
      this.$emit('closeModal', { from: 'updateCancel' })
    },
    useStyles(style) {
      switch (style) {
        case 'label-style':
          return { width: '150px' }
        case 'error-style':
          return { fontSize: '12px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <div>
    <!-- no selected row -->
    <div v-if="!rowSelected">
      <span>กรุณาเลือกสมุดรายวันที่ต้องการแก้ไข</span>
    </div>

    <!-- have selected row -->
    <div v-else>
      <!-- journalCode -->
      <div class="mb-3 d-flex align-items-center">
        <label class="mr-4" :style="useStyles('label-style')">
          รหัสสมุดรายวัน
        </label>
        <b-form-input
          v-model="journalCode"
          trim
          :disabled="true"
        ></b-form-input>
      </div>

      <!-- journalName -->
      <div class="mb-3 d-flex align-items-center">
        <label class="mr-4" :style="useStyles('label-style')">
          ชื่อสมุดรายวัน
        </label>
        <div class="w-100">
          <b-form-input
            v-model="journalName"
            :disabled="isLoading"
            trim
          ></b-form-input>
          <span
            class="pl-1 text-danger"
            :style="useStyles('error-style')"
            v-if="errors && errors.journalName"
          >
            {{ errors.journalName }}
          </span>
        </div>
      </div>

      <!-- error message from response -->
      <div v-if="errorsResponse" class="mb-3">
        <b-icon
          icon="exclamation-triangle-fill"
          variant="danger"
          class="mr-2"
          font-scale="1.5"
        ></b-icon>
        <span class="text-danger font-weight-bold">
          {{ errorsResponse.thMessage || errorsResponse.message }}
        </span>
      </div>

      <!-- buttons -->
      <hr />
      <b-row align-h="end">
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="outline-primary"
            @click="cancelButtonClicked()"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="primary"
            @click="updateButtonClicked()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>บันทึก</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped></style>
