<script>
import validator from 'validator'
import _ from 'lodash'

import ge2gjnlService from '@/services/entry/ge2gjnl.js'

export default {
  data() {
    return {
      journalCode: null,
      journalName: null,
      documentIdPattern: 'COMPUTER',
      documentIdPatternOptions: [
        { text: 'คอมพิวเตอร์', value: 'COMPUTER' },
        { text: 'กำหนดเอง', value: 'MANUAL' },
        { text: 'แบบพิเศษ ปี/เดือน/วันที่', value: 'SPECIAL' }
      ],
      yearTypeSelected: 'BE',
      yearTypeOptions: [
        { text: 'พ.ศ. (พุทธศักราช)', value: 'BE' },
        { text: 'ค.ศ. (คริสต์ศักราช)', value: 'CE' }
      ],
      length: null,
      latestRunningNumber: null,
      errors: null,
      errorsResponse: null,
      isLoading: false
    }
  },
  methods: {
    async saveButtonClicked() {
      this.errorsResponse = null
      if (!this.passValidationRule()) return
      try {
        this.isLoading = true
        const body = {
          journalCode: this.journalCode,
          journalName: this.journalName,
          documentIdPattern: this.documentIdPattern,
          length: this.length,
          latestRunningNumber: this.latestRunningNumber,
          yearType: this.yearTypeSelected
        }

        if (_.isString(body.length)) {
          if (body.length === '') {
            body.length = null
          } else {
            body.length = Number(body.length)
          }
        }

        if (_.isString(body.latestRunningNumber)) {
          if (body.latestRunningNumber === '') {
            body.latestRunningNumber = null
          } else {
            body.latestRunningNumber = Number(body.latestRunningNumber)
          }
        }

        switch (this.documentIdPattern) {
          case 'COMPUTER':
            body.yearType = 'BE'
            break
          case 'MANUAL':
            body.yearType = 'BE'
            break
        }

        await ge2gjnlService.createGe2gjnl(body)
        this.$emit('closeModal', {
          from: 'createSuccess',
          content: 'เพิ่มสมุดรายวันใหม่สำเร็จแล้ว',
          options: {
            title: `สำเร็จ`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            autoHideDelay: 1000,
            solid: false
          }
        })
      } catch (err) {
        this.errorsResponse = err.response.data
        this.$emit('closeModal', {
          from: 'createError',
          content: err.response.data.thMessage,
          options: {
            title: `ล้มเหลว`,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            autoHideDelay: 3000,
            solid: false
          }
        })
      }
      this.isLoading = false
    },
    passValidationRule() {
      this.errors = null
      const errors = {}

      // journalCode
      if (!this.journalCode || this.journalCode.trim().length <= 0) {
        errors.journalCode = 'จำเป็นต้องกรอก รหัสสมุดรายวัน'
      } else if (!validator.isAlphanumeric(this.journalCode.trim())) {
        errors.journalCode = 'รหัสสมุดรายวัน ใส่ได้แค่ a-z A-Z 0-9 เท่านั้น'
      } else if (
        !validator.isLength(this.journalCode.trim(), { min: 1, max: 6 })
      ) {
        errors.journalCode = 'รหัสสมุดรายวัน ใส่ได้มากสุดแค่ 6 ตัวอักษรเท่านั้น'
      }

      // journalName
      if (!this.journalName || this.journalName.trim().length <= 0) {
        errors.journalName = 'จำเป็นต้องกรอก ชื่อสมุดรายวัน'
      } else if (
        !validator.isLength(this.journalName.trim(), { min: 1, max: 30 })
      ) {
        errors.journalName = 'ชื่อสมุดรายวัน มีได้มากสุดแค่ 30 ตัวอักษรเท่านั้น'
      }

      // documentIdPattern
      if (
        !this.documentIdPattern ||
        this.documentIdPattern.trim().length <= 0
      ) {
        errors.documentIdPattern = 'จำเป็นต้องเลือก รูปแบบเลขที่เอกสาร'
      } else if (
        !this.documentIdPatternOptions
          .map(op => op.value)
          .includes(this.documentIdPattern)
      ) {
        errors.documentIdPattern =
          'รูปแบบเลขที่เอกสารต้องเป็น "คอมพิวเตอร์" หรือ "กำหนดเอง" หรือ "แบบพิเศษ" เท่านั้น'
      }

      // if errors is not empty then not pass
      if (!_.isEmpty(errors)) {
        this.errors = errors
        return false
      }
      return true
    },
    cancelButtonClicked() {
      this.$emit('closeModal', {
        from: 'createCancel'
      })
    },
    useStyles(style) {
      switch (style) {
        case 'label-style':
          return { width: '150px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <div>
    <!-- journalCode -->
    <div class="mb-3 d-flex align-items-center">
      <label class="mr-4" :style="useStyles('label-style')">
        รหัสสมุดรายวัน
      </label>
      <div class="w-100">
        <b-form-input
          v-model="journalCode"
          :disabled="isLoading"
          trim
        ></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.journalCode"
        >
          {{ errors.journalCode }}
        </span>
      </div>
    </div>

    <!-- journalName -->
    <div class="mb-3 d-flex align-items-center">
      <label class="mr-4" :style="useStyles('label-style')">
        ชื่อสมุดรายวัน
      </label>
      <div class="w-100">
        <b-form-input
          v-model="journalName"
          :disabled="isLoading"
          trim
        ></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.journalName"
        >
          {{ errors.journalName }}
        </span>
      </div>
    </div>

    <!-- documentIdPattern -->
    <div class="mb-3 d-flex align-items-center">
      <label class="mr-4" :style="useStyles('label-style')">
        กำหนดรูปแบบเลขที่เอกสาร
      </label>
      <div class="w-100">
        <b-form-select
          :options="documentIdPatternOptions"
          v-model="documentIdPattern"
          :disabled="isLoading"
          trim
        ></b-form-select>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.documentIdPattern"
        >
          {{ errors.documentIdPattern }}
        </span>
      </div>
    </div>

    <!-- yearType -->
    <div
      v-if="['SPECIAL'].includes(documentIdPattern)"
      class="mb-3 d-flex align-items-center"
    >
      <label class="mr-4" :style="useStyles('label-style')">
        ชนิดของปี
      </label>
      <div class="w-100">
        <b-form-select
          :options="yearTypeOptions"
          v-model="yearTypeSelected"
          :disabled="isLoading"
          trim
        ></b-form-select>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.yearType"
        >
          {{ errors.yearType }}
        </span>
      </div>
    </div>

    <!-- length -->
    <div
      v-if="['COMPUTER', 'MANUAL'].includes(documentIdPattern)"
      class="mb-3 d-flex align-items-center"
    >
      <label class="mr-4" :style="useStyles('label-style')">
        ความยาว
      </label>
      <div class="w-100">
        <b-form-input
          v-model="length"
          :disabled="isLoading"
          type="number"
          trim
        ></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.length"
        >
          {{ errors.length }}
        </span>
      </div>
    </div>

    <!-- latestRunningNumber -->
    <div
      v-if="['COMPUTER'].includes(documentIdPattern)"
      class="mb-3 d-flex align-items-center"
    >
      <label class="mr-4" :style="useStyles('label-style')">
        เลขที่เอกสารตัวสุดท้าย
      </label>
      <div class="w-100">
        <b-form-input
          v-model="latestRunningNumber"
          type="number"
          :disabled="isLoading"
          trim
        ></b-form-input>
        <span
          class="pl-1 text-danger error-style"
          v-if="errors && errors.latestRunningNumber"
        >
          {{ errors.latestRunningNumber }}
        </span>
      </div>
    </div>

    <!-- error message from response -->
    <div v-if="errorsResponse" class="mb-3">
      <b-icon
        icon="exclamation-triangle-fill"
        variant="danger"
        class="mr-2"
        font-scale="1.5"
      ></b-icon>
      <span class="text-danger font-weight-bold">
        {{ errorsResponse.thMessage || errorsResponse.message }}
      </span>
    </div>

    <!-- buttons -->
    <hr />
    <b-row align-h="end">
      <b-col cols="3">
        <b-button
          class="w-100"
          variant="outline-success"
          @click="cancelButtonClicked()"
          :disabled="isLoading"
        >
          ยกเลิก
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-button
          class="w-100"
          variant="success"
          @click="saveButtonClicked()"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" variant="light" small></b-spinner>
          <span v-else>บันทึก</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.error-style {
  font-size: 12px;
}
</style>
