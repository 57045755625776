<script>
import _ from 'lodash'

import { numberMixins, toastMixins } from '@/mixins'
import ge2gjnlService from '@/services/entry/ge2gjnl.js'
import MenuBar from '@/views/entry/ge2gjnl/components/MenuBar'

import { journalBookImportAdapter } from './helpers/adapter'

export default {
  mixins: [numberMixins, toastMixins],
  components: {
    MenuBar
  },
  data() {
    return {
      mode: null,
      rowSelected: null,
      sortBySelected: 'journalCode',
      sortByOptions: [
        { value: 'journalCode', text: 'รหัส' },
        { value: 'journalName', text: 'ชื่อสมุดรายการ' },
        { value: 'journalRunningNumber', text: 'จำนวนรวมรายการ' }
      ],
      sortTypeSelected: 'asc',
      sortTypeOptions: [
        { value: 'asc', text: 'แรกสุด - ท้ายสุด' },
        { value: 'desc', text: 'ท้ายสุด - แรกสุด' }
      ],
      searchKeyword: '',
      fields: [
        { field: 'journalCode', title: 'รหัส', align: 'center' },
        {
          field: 'journalName',
          title: 'ชื่อสมุดรายวัน',
          align: 'center'
        },
        {
          field: 'journalRunningNumberShow',
          title: 'รวมรายการ',
          align: 'center'
        }
      ],
      journalBooks: [],
      systemJournalBookCodes: [],
      errMsg: null
    }
  },
  computed: {
    displayJournalBooks() {
      const filteredJournalBooks = this.journalBooks.filter(journalBook => {
        journalBook.journalRunningNumberShow =
          journalBook.journalRunningNumber === 0
            ? '-'
            : this.mxNumeralThousandSeperate(
                journalBook.journalRunningNumber,
                0
              )
        const journalBookKeyword = `${journalBook.journalCode}${journalBook.journalName}`
        return journalBookKeyword.includes(this.searchKeyword)
      })
      return _.orderBy(
        filteredJournalBooks,
        [this.sortBySelected],
        [this.sortTypeSelected]
      )
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.setUpMode()
      await this.fetchSystemJournalBooks()
      await this.fetchAllGe2gjnlData()
    },
    setUpMode() {
      let mode = this.$route.query.mode
      if (!mode || !['user', 'system'].includes(mode)) this.$router.replace('/')
      this.mode = mode
    },
    async fetchSystemJournalBooks() {
      try {
        const res = await ge2gjnlService.getSystemJournalBookCodes()
        this.systemJournalBookCodes = res.data.data
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลสมุดรายวันของระบบไม่สำเร็จ'
        this.errMsg = errMessage
      }
    },
    async fetchAllGe2gjnlData() {
      try {
        const res = await ge2gjnlService.getAllGe2gjnl()
        this.journalBooks = res.data.data
          .map(el => journalBookImportAdapter(el))
          .filter(({ journalCode }) => {
            if (this.mode === 'user') {
              return !this.systemJournalBookCodes.includes(journalCode)
            } else if (this.mode === 'system') {
              return this.systemJournalBookCodes.includes(journalCode)
            } else {
              return false
            }
          })
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.rowSelected = null
      }
    },
    rowClickedHandler(selectedJournalBook) {
      this.rowSelected = selectedJournalBook
    },
    rowDoubleClickedHandler(e) {
      this.$router.push(`/entry/ge2gjnl/journal-book/${e.id}/detail`)
    },
    getRowCss(row) {
      if (this.rowSelected) {
        if (this.rowSelected.id === row.id) {
          return { backgroundColor: 'lightblue' }
        }
      }
      return null
    },
    useStyles(key) {
      switch (key) {
        case 'datagrid-style':
          return { height: '500px' }
        default:
          return {}
      }
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- แถบ menu ด้านบน -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <span class="text-xxl text-white" style="text-shadow: 2px 2px grey;">
          ป้อนรายการสมุดรายวัน
          {{
            mode === 'user' ? '- ป้อนเอง' : mode === 'system' ? '- โดยระบบ' : ''
          }}
        </span>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar
          :rowSelected="rowSelected"
          @updateTable="fetchAllGe2gjnlData"
        ></menu-bar>
      </b-col>
    </b-row>

    <!-- ส่วนของ selection & search -->
    <b-row class="my-3 d-flex justify-content-center">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-by-selection">
              เรียงตาม
            </label>
            <b-form-select
              id="sort-by-selection"
              v-model="sortBySelected"
              :options="sortByOptions"
              style="font-size: 0.9rem;"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="3">
            <label class="text-white text-sm" for="sort-type-selection">
              เรียงแบบ
            </label>
            <b-form-select
              id="sort-type-selection"
              v-model="sortTypeSelected"
              :options="sortTypeOptions"
              style="font-size: 0.9rem;"
            ></b-form-select>
          </b-col>
          <b-col cols="12" md="2"></b-col>
          <b-col cols="12" md="4">
            <label class="text-white text-sm" for="search-input">
              ค้นหา
            </label>
            <b-form-input
              id="search-input"
              v-model="searchKeyword"
              placeholder="รหัส ชื่อสมุดรายวัน"
              style="font-size: 0.9rem;"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ส่วนของ table -->
    <b-row class="d-flex flex-column align-items-center">
      <b-col cols="12" class="text-center">
        <div class="bg-light py-2">
          <h3>สมุดรายวัน</h3>
        </div>
      </b-col>
      <b-col cols="12" class="text-center">
        <DataGrid
          :data="displayJournalBooks"
          :rowCss="getRowCss"
          :style="useStyles('datagrid-style')"
          @rowClick="rowClickedHandler"
          @rowDblClick="rowDoubleClickedHandler"
        >
          <GridColumn
            v-for="field in fields"
            :key="field.field"
            :field="field.field"
            :title="field.title"
            :align="field.align"
          ></GridColumn>
        </DataGrid>
      </b-col>
    </b-row>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style scoped>
.text-sm {
  margin-left: 5px;
}
</style>
