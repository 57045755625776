<script>
import CreateModal from './CreateModal.vue'
import DeleteModal from './DeleteModal.vue'
import UpdateModal from './UpdateModal.vue'
// import ExportPdf from './ExportPdf.vue';

export default {
  components: {
    CreateModal,
    UpdateModal,
    DeleteModal
    // ExportPdf
  },
  props: {
    rowSelected: Object
  },
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2',
      modalShow: false,
      currentModal: null,
      modalThemeColor: null,
      modalTitle: null,
      exportPdfClicked: false,
      user: null
    }
  },
  created() {
    this.user = this.$store.state.auth.user
  },
  methods: {
    buttonClicked(type) {
      switch (type) {
        case 'REFRESH':
          location.reload()
          break
        case 'CREATE':
          this.currentModal = 'create-modal'
          this.modalThemeColor = 'success'
          this.modalTitle = 'เพิ่มสมุดรายวัน'
          this.modalShow = true
          break
        case 'READ':
          if (this.rowSelected) {
            this.$router.push(
              `/entry/ge2gjnl/journal-book/${this.rowSelected.id}/detail`
            )
          } else {
            alert('กรุณาเลือกสมุดรายวันที่ต้องการดูรายละเอียด')
          }
          break
        case 'UPDATE':
          this.currentModal = 'update-modal'
          this.modalThemeColor = 'primary'
          this.modalTitle = 'แก้ไขสมุดรายวัน'
          this.modalShow = true
          break
        case 'DELETE':
          this.currentModal = 'delete-modal'
          this.modalThemeColor = 'danger'
          this.modalTitle = 'ลบสมุดรายวัน'
          this.modalShow = true
          break
        case 'EXPORT':
          this.exportPdfClicked = true
          alert('ยังไม่เปิดการใช้งานฟีเจอร์นี้')
          break
        case 'EXIT':
          this.$router.push({ name: 'Home' })
          break
      }
    },
    closeModalHandler(event) {
      if (event.from.includes('Success') || event.from.includes('Cancel')) {
        this.modalShow = false
      }
      if (
        event &&
        !['createCancel', 'updateCancel', 'deleteCancel'].includes(event.from)
      ) {
        this.$bvToast.toast(event.content, event.options)
      }
      if (
        event &&
        ['createSuccess', 'updateSuccess', 'deleteSuccess'].includes(event.from)
      ) {
        this.$emit('updateTable')
      }
    },
    reportGeneratedHandler() {
      this.exportPdfClicked = false
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- CREATE BUTTON -->
      <div v-if="user && user.permission.entryJournal.canAddNew">
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('CREATE')"
          v-b-tooltip.hover.bottom.v-success="'สร้างสมุดรายวันใหม่'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="plus"></b-icon>
          <span class="text-sm">สร้าง</span>
        </b-button>
      </div>

      <!-- UPDATE BUTTON -->
      <div v-if="user && user.permission.entryJournal.canUpdate">
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขสมุดรายวันเดิม'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- DELETE BUTTON -->
      <div v-if="user && user.permission.entryJournal.canDelete">
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบสมุดรายวันเดิม'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="trash"></b-icon>
          <span class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- READ BUTTON -->
      <div v-if="user && user.permission.entryJournal.canView">
        <b-button
          :class="buttonClass"
          variant="warning"
          @click="buttonClicked('READ')"
          v-b-tooltip.hover.bottom.v-warning="'ดูรายละเอียดสมุดรายวัน'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="eye"></b-icon>
          <span class="text-sm">รายละเอียด</span>
        </b-button>
      </div>

      <!-- EXPORT BUTTON -->
      <div v-if="user && user.permission.entryJournal.canPrint">
        <b-button
          :class="buttonClass"
          variant="info"
          @click="buttonClicked('EXPORT')"
          v-b-tooltip.hover.bottom.v-info="'ออกรายงานสมุดรายวัน'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="file-earmark-text"
          ></b-icon>
          <span class="text-sm">พิมพ์</span>
        </b-button>
      </div>

      <!-- EXIT BUTTON -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- MODAL DISPLAY -->
      <b-modal
        v-model="modalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        header-text-variant="light"
        :hide-footer="true"
        :size="currentModal === 'read-modal' ? 'xl' : 'md'"
      >
        <component
          :is="currentModal"
          :rowSelected="rowSelected"
          @closeModal="closeModalHandler"
        ></component>
      </b-modal>
    </div>

    <div class="d-none">
      <!-- <export-pdf
        :click='exportPdfClicked'
        @reportGenerated='reportGeneratedHandler'
      ></export-pdf> -->
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
